<template>
  <div :class="type">
    <shared-show
      :type="type"
      :inputs="inputs"
      :lefts="leftCards"
      :rights="rightCards"
    />
  </div>
</template>

<script>
import SharedShow from '@/components/shared/show.vue'

export default {
  name: 'GovernmentAgencyDetail',
  components: {
    SharedShow
  },
  data() {
    return {
      type: 'government-agency',
      inputs: ['name', 'ordered', 'qr_code', 'vrtour', 'description', 'content'],
      leftCards: ['groups'],
      rightCards: [
        'display',
        'price',
        'days',
        'hours',
        'address',
        'contact',
        'category',
        'youtube',
        'audios',
        'pictures',
        'videos'
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
